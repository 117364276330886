import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowLookAlike,
  setShowTrashcanLookAlike,
  setFile,
  setLogedin,
  setFetchUser,
  setItemNames,
  setCopyItemNames,
  setTotalItems,
  setSessionVar,
  setOutfitsListPush,
  setOutfitToShow,
  resetOutfitsList,
  setSqlStyle,
  setSqlUser,
  setSqlSeason,
  setMaxAttempts,
  setAllItemsUser,
  setMainImage3,
} from "./displayContainerSlice";
import { setLoaderVar } from "../../comps/header/LoginButton/loginButtonSlice";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import DisplayBox from "../../comps/DisplayBox/DisplayBox";
import LoginButton from "../../comps/header/LoginButton/LoginButton";
import "./displayContainer.css";
import jwtDecode from "jwt-decode";
import api from "../../api/api";
// * For accuweather and Python
import axios from "axios";
import dayjs from "dayjs";

const APP_API_URL = "https://kkaada.com";
const apiUrl = process.env.REACT_APP_LOCAL
  ? "http://localhost:4000"
  : process.env.REACT_APP_STORAGE;
let emptyWardrobeItemPath = require(`../../assets/images/others/empty-wardrobe.png`);
// * For converting birth date to age
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

function DisplayContainer() {
  const dispatch = useDispatch();
  let userEmail = "";

  // * Inserts, in an initial and one-time manner, a result into the presentation
  let done = false;
  const addToOutfitsList = (allFirstResults) => {
    if (allFirstResults.length === 0) {
      console.error("outfitToShow is empty");
      return;
    }
    const randomIndex = Math.floor(Math.random() * allFirstResults.length);
    const randomValue = allFirstResults[randomIndex];
    const nonNullKeys = {};
    for (const key in randomValue) {
      if (key !== "accnumber" && randomValue[key] !== null) {
        nonNullKeys[key] = randomValue[key];
      }
    }
    if (
      JSON.stringify(nonNullKeys) !== "[{}]" &&
      JSON.stringify(nonNullKeys) !== "{}" &&
      !done
    ) {
      done = true;
      dispatch(setOutfitsListPush(nonNullKeys));
    }
  };

  // * Create Display
  const itemToShow = useSelector((state) => state.displayContainer.itemToShow);
  const displayList = useSelector(
    (state) => state.displayContainer.displayList
  );
  const outfitsList = useSelector(
    (state) => state.displayContainer.outfitsList
  );
  const [column1With2Boxes, setColumn1With2Boxes] = useState(null);
  const [column2With4Boxes, setColumn2With4Boxes] = useState(null);
  const [column3With4Boxes, setColumn3With4Boxes] = useState(null);
  const [column4With4Boxes, setColumn4With4Boxes] = useState(null);

  // * For saving & rendering changes regarding look-alike images
  const [mainImage, setMainImage] = useState(false);
  const [mainImage2, setMainImage2] = useState(true);
  const mainImage3 = useSelector((state) => state.displayContainer.mainImage3);

  useEffect(() => {
    try {
      const foundItem = displayList.find(
        (item) =>
          JSON.stringify(item.scenario) ===
          JSON.stringify(Object.keys(outfitsList?.[itemToShow] ?? {}))
      );

      if (foundItem) {
        const resultKeys = Object.keys(foundItem.result);

        const generateColumnContent = async (keys) => {
          return Promise.all(
            keys.map(async (key, index) => {
              const classs = foundItem.result[key][0];
              const imageKey = foundItem.result[key][1];

              try {
                if (outfitsList && outfitsList[itemToShow]) {
                  let fileName = outfitsList[itemToShow][imageKey];

                  if (fileName) {
                    let imagePathResponse;
                    try {
                      // * If user marked "Choose a look-alike image for this clothing item" rubric
                      imagePathResponse = await api.get(
                        `updateUserWardrobe/lookalikefilepath/${userEmail}/${fileName}`
                      );
                      // * Necessary because there's a delay in receiving the updated value from the database when the view has to show the item after changes
                      await new Promise((resolve) => setTimeout(resolve, 10));
                      imagePathResponse = await api.get(
                        `updateUserWardrobe/lookalikefilepath/${userEmail}/${fileName}`
                      );
                      // * Instead of delaying more time the second time, a third time is necessary, otherwise it will not render after updating an item as the main image the first time after uploading an image.
                      await new Promise((resolve) => setTimeout(resolve, 10));
                      imagePathResponse = await api.get(
                        `updateUserWardrobe/lookalikefilepath/${userEmail}/${fileName}`
                      );
                    } catch (error) {
                      console.error("Error fetching image path:", error);
                    }

                    const imageSrc =
                      imagePathResponse.data.mainImage &&
                      imagePathResponse.data.filePath.includes("uploads")
                        ? `${apiUrl}/uploads${
                            imagePathResponse.data.filePath.split("uploads")[1]
                          }`
                        : imagePathResponse.data.mainImage &&
                          imagePathResponse.data.filePath.includes("upload")
                        ? `${apiUrl}/uploads${
                            imagePathResponse.data.filePath.split("upload")[1]
                          }`
                        : `${apiUrl}/images/${fileName}.0.jpg`;

                    return (
                      <div className="d-flex flex-row" key={index}>
                        <DisplayBox
                          classs={classs}
                          hover="img2"
                          image={imageSrc}
                          handleShowForLookAlike={() =>
                            handleShowForLookAlike(
                              `${apiUrl}/images/${fileName}.0.jpg`
                            )
                          }
                        />
                      </div>
                    );
                  }
                }
              } catch (error) {
                console.log("Error fetching file path:", error);
              }
            })
          );
        };

        generateColumnContent(resultKeys.slice(0, 2)).then((content) =>
          setColumn1With2Boxes(content)
        );
        generateColumnContent(resultKeys.slice(2, 6)).then((content) =>
          setColumn2With4Boxes(content)
        );
        generateColumnContent(resultKeys.slice(6, 10)).then((content) =>
          setColumn3With4Boxes(content)
        );
        generateColumnContent(resultKeys.slice(10, 14)).then((content) =>
          setColumn4With4Boxes(content)
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, [itemToShow, outfitsList, mainImage3]);

  // * Updating to the current user
  let decodedToken = null;
  let jwtCookie = null;
  if (Cookies.get("jwt")) {
    jwtCookie = Cookies.get("jwt");
    userEmail = jwtDecode(Cookies.get("jwt")).user;
  }
  let currentUserFetched = useSelector(
    (state) => state.displayContainer.fetchUser
  );
  // * Used to update/un-update store and outfit list
  const sessionVar = useSelector((state) => state.displayContainer.sessionVar);
  if (
    (!sessionVar || Cookies.get("sessionCookie") === "false") &&
    Cookies.get("jwt")
  ) {
    dispatch(setSessionVar(true));
    Cookies.set("sessionCookie", false);
    dispatch(setLoaderVar(true));
    getClothes();
  }
  // * For updating the store wardrobe
  async function getClothes() {
    if (Cookies.get("sessionCookie") === "false") {
      try {
        const res = await api.post(`/clothes`);
        const { totalItems, itemNames } = res.data;
        dispatch(setItemNames(itemNames));
        dispatch(setCopyItemNames(itemNames));
        dispatch(setTotalItems(totalItems));
      } catch (error) {
        console.error("Failed to import store wardrobe:", error);
      }
    }
  }

  // * For sending clothing items associated with the user to be generated into outfits
  let allItemsUser = useSelector(
    (state) => state.displayContainer.allItemsUser
  );

  // * For sending a query with a weather parameter
  let sqlSeason = ["winter", "summer", "autumn", "spring"];
  // * For sending Styles & Seasons associated with user's filter
  let Styles = useSelector((state) => state.filter2.Styles);
  let Seasons = useSelector((state) => state.filter2.Seasons);

  async function getList() {
    let sqlUser;
    let sqlUserSkin;
    if (currentUserFetched.appearance.estimated.gender === "") {
      currentUserFetched.appearance.accurate.skin > 2
        ? (sqlUserSkin = "dark")
        : (sqlUserSkin = "light");
      sqlUser = {
        height: currentUserFetched.appearance.accurate.height,
        age: currentUserFetched.appearance.accurate.age,
        gender: currentUserFetched.appearance.accurate.gender,
        weight: currentUserFetched.appearance.accurate.weight,
        wrist: currentUserFetched.appearance.accurate.wrist,
        bust: currentUserFetched.appearance.accurate.bust,
        waist: currentUserFetched.appearance.accurate.waist,
        hips: currentUserFetched.appearance.accurate.hips,
        skin: sqlUserSkin,
      };
    } else {
      const userSkin =
        currentUserFetched.appearance.estimated.skin > 2 ? "dark" : "light";
      const userFat =
        currentUserFetched.appearance.estimated.dimension > 3
          ? "large"
          : currentUserFetched.appearance.estimated.dimension < 3
          ? "small"
          : "medium";
      const userWaist =
        (currentUserFetched.appearance.estimated.shape === "3" ||
          currentUserFetched.appearance.estimated.shape === "5") &&
        userFat === "small"
          ? "medium"
          : (currentUserFetched.appearance.estimated.shape === "3" ||
              currentUserFetched.appearance.estimated.shape === "5") &&
            userFat !== "small"
          ? "large"
          : userFat;
      const userHips =
        (currentUserFetched.appearance.estimated.shape === "5" &&
          userFat === "large") ||
        (currentUserFetched.appearance.estimated.shape === "2" &&
          userFat !== "small")
          ? "large"
          : "medium";
      sqlUser = {
        skin: userSkin,
        bodyFat: userFat,
        waist: userWaist,
        hips: userHips,
        height: currentUserFetched.appearance.estimated.height,
      };
    }
    let sqlStyle = [];
    // * Retrieving the user's style preferences
    function createStyleArrayFromItems(items) {
      const styleArray = [];
      const styleCounts = {};
      items.forEach((item) => {
        if (item.checked) {
          const style = item.value;
          if (!styleCounts[style]) {
            styleCounts[style] = 1;
          } else {
            styleCounts[style]++;
          }
        }
      });
      items.forEach((item) => {
        if (item.checked) {
          styleArray.push(item.value);
        }
      });
      let sum = 0;
      for (let index = 0; index < Object.keys(styleCounts).length; index++) {
        const style = Math.round(
          (styleArray.length / Object.keys(styleCounts).length) *
            Object.values(styleCounts)[index]
        );
        sum = sum + style;
      }
      for (let index = 0; index < Object.keys(styleCounts).length; index++) {
        const keyName = Object.keys(styleCounts)[index];
        const style = Math.round(
          (styleArray.length / Object.keys(styleCounts).length) *
            Object.values(styleCounts)[index]
        );
        const out = Math.round((style / sum) * 10);
        for (let index = 0; index < out; index++) {
          sqlStyle.push(keyName);
        }
      }
    }
    createStyleArrayFromItems(currentUserFetched.preferences);
    dispatch(setSqlUser(sqlUser));
    dispatch(setSqlStyle(sqlStyle));
    dispatch(setSqlSeason(sqlSeason));
    const allFirstResults = [];
    // * Essential for mysql requests not to collide because the requests at the same time
    let checkIfDone = false;
    const allResults = [];
    const user =
      currentUserFetched.appearance.estimated.gender !== ""
        ? {
            skin: sqlUser.skin,
            bodyFat: sqlUser.bodyFat,
            waist: sqlUser.waist,
            hips: sqlUser.hips,
            height: sqlUser.height,
            season: Seasons ? Seasons : sqlSeason,
            style: Styles ? Styles : sqlStyle,
            items: allItemsUser,
          }
        : {
            email: userEmail,
            skin: sqlUser.skin,
            bust: parseInt(sqlUser.bust),
            waist: parseInt(sqlUser.waist),
            hips: parseInt(sqlUser.hips),
            height: parseInt(sqlUser.height),
            wrist: parseInt(sqlUser.wrist || 0),
            weight: parseInt(sqlUser.weight || 0),
            gender: sqlUser.gender,
            age: parseInt(dayjs().from(dayjs(sqlUser.age), true).split(" ")[0]),
            season: Seasons ? Seasons : sqlSeason,
            style: Styles ? Styles : sqlStyle,
            items: allItemsUser,
          };

    let firstResponse = "";
    try {
      if (currentUserFetched.appearance.estimated.gender !== "") {
        if (!process.env.REACT_APP_LOCAL) {
          firstResponse = await axios.post(`/python/estifirst`, user);
        } else
          firstResponse = await axios.post(
            "http://127.0.0.1:8000/estifirst",
            user
          );
      } else if (!process.env.REACT_APP_LOCAL) {
        firstResponse = await axios.post(`/python/first`, user);
      } else
        firstResponse = await axios.post("http://127.0.0.1:8000/first", user);
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    if (!firstResponse.data) {
      alert(
        "There was an error, please try again later (if the error persists, contact customer support)."
      );
    } else if (Array.isArray(firstResponse.data)) {
      firstResponse.data.forEach((item) => {
        if (Array.isArray(item)) {
          const result = item;
          allFirstResults.push(...result);
          allResults.push(...result);
        }
      });
      dispatch(setOutfitToShow(allFirstResults));
      dispatch(setMaxAttempts(false));
      addToOutfitsList(allFirstResults);
      Cookies.set("sessionCookie", true);

      checkIfDone = true;
    }
    let restResponse = "";
    if (checkIfDone) {
      try {
        if (currentUserFetched.appearance.estimated.gender !== "") {
          if (!process.env.REACT_APP_LOCAL) {
            restResponse = await axios.post(`/python/estirest`, user);
          } else
            restResponse = await axios.post(
              "http://127.0.0.1:8000/estirest",
              user
            );
        } else if (!process.env.REACT_APP_LOCAL) {
          restResponse = await axios.post(`/python/rest`, user);
        } else
          restResponse = await axios.post("http://127.0.0.1:8000/rest", user);
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      if (!restResponse.data && checkIfDone) {
        alert(
          "There was an error, please try again later (if the error persists, contact customer support)."
        );
      } else if (Array.isArray(restResponse.data)) {
        restResponse.data.forEach((item) => {
          if (Array.isArray(item)) {
            const result = item;
            allResults.push(...result);
          }
        });
        dispatch(setOutfitToShow(allResults));
      }
    }
  }

  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const routeFromLink = urlParts[4];
  // * Password reset
  const resetUserPass = async (userToken, userPass) => {
    try {
      const response = await api.get(
        `/user/resetPass/${userToken}/${userPass}`
      );
      console.log(response.data.message);
    } catch (error) {
      console.error("Error reseting password:", error);
    }
  };
  // * Activation for a new user
  const confirmUser = async (userId) => {
    try {
      const response = await api.get(`/confirm/${userId}`);
      console.log(response.data.message);
    } catch (error) {
      console.error("Error confirming user:", error);
    }
  };

  // * Required for weather request
  function isValidIPAddress(ipAddress) {
    const ipAddressRegex =
      /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.((25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.){2}(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/;
    return ipAddressRegex.test(ipAddress);
  }

  useEffect(() => {
    try {
      if (
        typeof JSON.parse(urlParts[urlParts.length - 1]) === "number" &&
        routeFromLink === "confirm"
      ) {
        confirmUser(urlParts[urlParts.length - 1]);
      }
    } catch (error) {}
    try {
      if (routeFromLink === "user") {
        resetUserPass(
          urlParts[urlParts.length - 2],
          urlParts[urlParts.length - 1]
        );
      }
    } catch (error) {}
  }, []);

  const getLocation = async () => {
    try {
      if (navigator.geolocation) {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const { latitude, longitude } = position.coords;
        const location = { latitude, longitude };

        return location;
      } else {
        console.log("Geolocation is not supported by this browser.");
        throw new Error("Geolocation is not supported by this browser.");
      }
    } catch (error) {
      console.error("Error getting user location:", error.message);

      // * If GPS fails or is not available, fall back to IP address
      try {
        const response = await api.get(`/user/ip`);
        const ipAddress = response.data;
        if (ipAddress !== false) {
          return { ipAddress };
        } else {
          console.error("Invalid IP address:", ipAddress);
          throw new Error("Invalid IP address");
        }
      } catch (error) {
        console.error("Error getting user IP:", error.message);
      }
    }
  };

  const fetchAccuWeatherData = async (location) => {
    let cityCode = "";
    if (location.latitude && location.longitude) {
      const accuWeatherUrl = `https://dataservice.accuweather.com/locations/v1/cities/geoposition/search?apikey=7ylqXlZFFmeM3lg282VuIUXVqfvoUADA&q=${location.latitude},${location.longitude}`;

      try {
        const response = await fetch(accuWeatherUrl);
        const data = await response.json();
        cityCode = data.Key;

        const apiResponse = await axios.get(
          `https://dataservice.accuweather.com/forecasts/v1/daily/1day/${cityCode}?apikey=7ylqXlZFFmeM3lg282VuIUXVqfvoUADA&metric=true`
        );

        let X =
          (apiResponse.data.DailyForecasts[0].Temperature.Maximum.Value +
            apiResponse.data.DailyForecasts[0].Temperature.Minimum.Value) /
          2;
        X > 30
          ? (X = "summer")
          : X > 23 && X <= 30
          ? (X = "spring")
          : X > 18 && X <= 23
          ? (X = "autumn")
          : (X = "winter");

        if (["summer", "spring", "autumn", "winter"].includes(X)) {
          dispatch(setSqlSeason([X]));
          sqlSeason = [X];
        }
      } catch (error) {
        console.error("Error fetching AccuWeather data:", error);
      }
    } else {
      if (isValidIPAddress(location.ipAddress.userIp)) {
        const accuWeatherUrl = `http://dataservice.accuweather.com/locations/v1/cities/ipaddress?apikey=7ylqXlZFFmeM3lg282VuIUXVqfvoUADA&q=${location}`;
        await fetch(accuWeatherUrl)
          .then((response) => response.json())
          .then((data) => {
            cityCode = data.Key;
          })
          .catch((error) =>
            console.error("Error fetching AccuWeather city code:", error)
          );
        const apiResponse = await axios.get(
          `http://dataservice.accuweather.com/forecasts/v1/daily/1day/${cityCode}?apikey=7ylqXlZFFmeM3lg282VuIUXVqfvoUADA&metric=true`
        );
        let X =
          (apiResponse.data.DailyForecasts[0].Temperature.Maximum.Value +
            apiResponse.data.DailyForecasts[0].Temperature.Minimum.Value) /
          2;
        X > 30
          ? (X = "summer")
          : X > 23 && X <= 30
          ? (X = "spring")
          : X > 18 && X <= 23
          ? (X = "autumn")
          : (X = "winter");

        if (["summer", "spring", "autumn", "winter"].includes(X)) {
          dispatch(setSqlSeason([X]));
          sqlSeason = [X];
        }
      }
    }
  };

  const currentUser = async () => {
    if (Cookies.get("sessionCookie") === "false" && jwtCookie) {
      try {
        decodedToken = jwtDecode(jwtCookie);
        try {
          const response = await api.get(
            `/updateUserWardrobe/items/${decodedToken.user}`
          );
          allItemsUser = response.data.allItemsUser;
          dispatch(setAllItemsUser(allItemsUser));
        } catch (error) {
          console.error("Error getting items:", error);
        }
        const res = await api.post(`/users/currentuser`, {
          email: decodedToken.user,
        });
        dispatch(setFetchUser(res.data));
        currentUserFetched = res.data;
        // * For new users without updated settings
        const accurateKeys = Object.keys(res.data.appearance.accurate);
        const estimatedKeys = Object.keys(res.data.appearance.estimated);
        const preferencesChecked = res.data.preferences.every(
          (pref) => pref.checked === false
        );
        const isAccurateEmpty = accurateKeys.every(
          (key) => res.data.appearance.accurate[key] === ""
        );
        const isEstimatedEmpty = estimatedKeys.every(
          (key) => res.data.appearance.estimated[key] === ""
        );
        if ((isAccurateEmpty && isEstimatedEmpty) || preferencesChecked) {
          window.location = "/setUser";
        } else getList();
      } catch (error) {
        console.error("JWT verification failed:", error);
      }
      return decodedToken.user;
    }
  };

  // * For updating a logged in user
  const logedin = useSelector((state) => state.displayContainer.logedin);
  if (jwtCookie) {
    dispatch(setLogedin(true));
  }

  useEffect(() => {
    // * For accuweather API
    const fetchData = async () => {
      try {
        if (Cookies.get("sessionCookie") === "false") {
          const userLocation = await getLocation();
          await fetchAccuWeatherData(userLocation);
        }
      } catch (error) {
        console.error("Error in initial data fetching:", error.message);
      }
    };
    const mainEffect = async () => {
      if (logedin) {
        await fetchData();
        currentUser();
      }
    };

    mainEffect();
  }, [logedin]);

  // * For a look-alike item image
  const showLookAlike = useSelector(
    (state) => state.displayContainer.showLookAlike
  );
  const [imagePath, setImagePath] = useState(false);
  const handleCloseForLookAlike = () => {
    dispatch(setShowLookAlike(false));
    setFileInput(false);
    setCheckboxVar(false);
  };
  const [imageName, setImageName] = useState(false);
  const handleShowForLookAlike = async (imagePath) => {
    setImagePath(imagePath);
    setImageName(
      imagePath.substring(imagePath.lastIndexOf("/") + 1).split(".")[0]
    );
    let imageName2 = imagePath
      .substring(imagePath.lastIndexOf("/") + 1)
      .split(".")[0];
    let lookalikefilepath = "";
    try {
      lookalikefilepath = await api.get(
        `updateUserWardrobe/lookalikefilepath/${userEmail}/${imageName2}`
      );
    } catch (error) {
      console.log("Error fetching file path:", error);
    }
    setMainImage(
      lookalikefilepath.data.mainImage
        ? lookalikefilepath.data.mainImage
        : false
    );
    dispatch(setMainImage3(mainImage3 + 2));
    setMainImage2(
      lookalikefilepath.data.mainImage
        ? lookalikefilepath.data.mainImage
        : false
    );
    let imageUrl = "";
    try {
      imageUrl = lookalikefilepath.data.filePath.includes("uploads")
        ? `${apiUrl}/uploads${
            lookalikefilepath.data.filePath.split("uploads")[1]
          }`
        : `${apiUrl}/uploads${
            lookalikefilepath.data.filePath.split("upload")[1]
          }`;
    } catch (error) {}
    dispatch(setFile(imageUrl ? imageUrl : imagePath));
    dispatch(setShowLookAlike(true));
  };
  // * For look-alike item image deletion
  const showTrashcanLookAlike = useSelector(
    (state) => state.displayContainer.showTrashcanLookAlike
  );
  const handleDeleteLookAlikeFile = async () => {
    try {
      await api.delete(
        `updateUserWardrobe/deleteLookalike/${userEmail}/${
          imagePath.substring(imagePath.lastIndexOf("/") + 1).split(".")[0]
        }`
      );
      handleCloseForTrashcanLookAlike();
      handleCloseForLookAlike();
      handleShowForLookAlike(imagePath);
    } catch (error) {
      console.log("Error fetching file path:", error);
    }
  };
  const handleCloseForTrashcanLookAlike = () => {
    dispatch(setShowTrashcanLookAlike(false));
  };
  const handleShowForTrashcanLookAlike = () => {
    dispatch(setShowTrashcanLookAlike(true));
  };
  // * Saving an uploaded image as a variable
  const file = useSelector((state) => state.displayContainer.file);
  // * Toolkit store is not recommended for saving a file type as a value in a var
  const [fileInput, setFileInput] = useState(false);
  const [checkboxVar, setCheckboxVar] = useState(false);
  const handleUpload = (e) => {
    dispatch(setFile(URL.createObjectURL(e.target.files[0])));
    setFileInput(e.target.files[0]);
    setCheckboxVar(true);
  };
  const handleMainImageChange = () => {
    setMainImage(!mainImage);
  };

  // For a valid image alert to upload
  function checkPromiseState(promise) {
    return promise.then(() => "fulfilled").catch(() => "rejected");
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (!fileInput) {
      if (mainImage === mainImage2) {
        alert("No changes were made");
        return;
      }
    }
    if (fileInput) {
      if (file.includes("uploads")) {
        const X = async () => {
          try {
            await api.delete(
              `updateUserWardrobe/deleteLookalike/${userEmail}/${imageName}`
            );
          } catch (error) {
            console.log("Error fetching file path:", error);
          }
        };
        X();
      }
      const formData = new FormData();
      formData.append("file", fileInput);
      const up = api.post(
        `updateUserWardrobe/upload/${userEmail}/${imageName}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      checkPromiseState(up).then((state) => {
        if (state === "rejected") {
          alert("Please choose an image type file (up to 5MB)");
        }
      });
    }
    if (mainImage !== mainImage2) {
      api.post(
        `updateUserWardrobe/lookalikemainimage/${userEmail}/${imageName}`
      );
      setMainImage2(mainImage);
    }
    dispatch(setMainImage3(mainImage3 + 1));
    handleCloseForLookAlike();
  }

  return (
    <div className="big-con modalResponsive">
      <div
        className="row container-fluid no-margin-no-padding display-boxs"
        style={{ border: "black 1px solid" }}
      >
        {!logedin || outfitsList.length === 0 ? (
          <span
            style={{ marginTop: "50%", position: "absolute", padding: "0" }}
            className="topResponsive"
          >
            <div>
              <LoginButton logedin={logedin} />
            </div>
            <div>
              <img
                style={{ width: "100%" }}
                alt="No-content"
                src={emptyWardrobeItemPath}
              />
              <div>
                <span
                  style={{
                    fontSize: "50px",
                    display: "grid",
                    justifyContent: "center",
                    color: "darkcyan",
                  }}
                >
                  No Outfits Yet? Give it a moment
                </span>
                <div
                  style={{
                    fontSize: "40px",
                    display: "grid",
                    justifyContent: "center",
                    color: "darkcyan",
                  }}
                >
                  Once adding additional clothing items to your wardrobe,
                </div>
                <div
                  style={{
                    fontSize: "40px",
                    display: "grid",
                    justifyContent: "center",
                    color: "darkcyan",
                  }}
                >
                  additional outfit suggestions will be generated hare
                </div>
              </div>
            </div>
          </span>
        ) : (
          <>
            {/* // * popup for look-alike item */}
            <Modal
              show={showLookAlike}
              onHide={handleCloseForLookAlike}
              dialogClassName="modalResponsive"
            >
              <Modal.Header closeButton>
                <Modal.Title>Associated item image</Modal.Title>
                <div>
                  <br />
                  <h6
                    style={{
                      position: "absolute",
                      left: "0",
                      fontSize: "0.75rem",
                      padding: "5px",
                      paddingLeft: "17px",
                    }}
                    className="text-muted"
                  >
                    Assigning a look-alike image to an item
                  </h6>
                </div>
              </Modal.Header>
              <Modal.Body style={{ display: "contents" }}>
                <div style={{ display: "contents" }}>
                  <Button
                    variant={
                      file.includes("uploads")
                        ? "outline-danger"
                        : "outline-secondary"
                    }
                    style={{
                      fontSize: "50%",
                      margin: "15px 0px 0px 15px",
                      width: "0.5rem",
                    }}
                    onClick={
                      file.includes("uploads")
                        ? handleShowForTrashcanLookAlike
                        : () => false
                    }
                  >
                    <i
                      className="fa-solid fa-trash-can"
                      style={{
                        fontSize: "1rem",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    ></i>
                  </Button>{" "}
                  <img
                    alt="Lookalike"
                    style={{ alignSelf: "center", width: "76%" }}
                    src={file}
                  />{" "}
                  <Form.Group controlId="formFile" className="mb-3">
                    <div style={{ padding: "0 36px" }}>
                      <Form.Label>
                        Choose a look-alike image for this clothing item
                      </Form.Label>
                      <Form.Control type="file" onChange={handleUpload} />
                      {file.includes("uploads") || checkboxVar ? (
                        <label style={{ marginTop: "0.5rem" }}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={mainImage}
                            onChange={() => handleMainImageChange()}
                          />
                          &nbsp; Use look-alike image as main image
                        </label>
                      ) : (
                        <label
                          style={{ marginTop: "0.5rem", color: "gainsboro" }}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            disabled={true}
                            checked={false}
                          />
                          &nbsp; Use look-alike image as main image
                        </label>
                      )}
                    </div>
                  </Form.Group>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseForLookAlike}>
                  Cancel
                </Button>
                <Button variant="success" onClick={handleSubmit}>
                  Save changes
                </Button>
              </Modal.Footer>
            </Modal>
            {/* // * popup for deleting a look-alike item */}
            <Modal
              show={showTrashcanLookAlike}
              onHide={handleCloseForTrashcanLookAlike}
            >
              <Modal.Header closeButton>
                <Modal.Title>Delete associated image?</Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleCloseForTrashcanLookAlike}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteLookAlikeFile()}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>

            <div className="col-3 no-margin-no-padding">
              <LoginButton logedin={logedin} />
              <div>{column1With2Boxes}</div>
            </div>
            <div className="col col-3 no-margin-no-padding">
              <div>{column2With4Boxes}</div>
            </div>
            <div className="col col-3 no-margin-no-padding">
              <div>{column3With4Boxes}</div>
            </div>
            <div className="col col-3 no-margin-no-padding">
              <div>{column4With4Boxes}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default DisplayContainer;
